



































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import rowEditor from '../../components/TextRowEditor.vue'
import rowSelector from '../../components/RowSelector.vue'
import { ApiBooleanResult } from '@/services/data'
import RssFeedForm from '@/views/Home/RssFeedForm.vue'
import { RssFeeds } from '@/services/symbolService'
import settingService from '@/services/settingService'
import { ElForm } from 'element-ui/types/form'
import utility from '@/common/utility'
import socket from '@/services/socket'

@Component({
  components: {
    rowEditor,
    RssFeedForm,
    rowSelector
  }
})
export default class UserSettings extends Vue {
  $refs: {
    gateWayForm: ElForm;
    notBotForm: ElForm;
  }

  showAddFeedDialog = false
  gateway = {
    Host: '',
    Port: ''
  }

  rssList: RssFeeds[] = []

  editingFeed: RssFeeds = {
    SymbolId: null,
    FeedIcon: '',
    FeedName: '',
    Link: ''
  }

  ibRotation: Record<string, number> = {
    FreebieLimit: null,
    CurrencyLimit: null,
    WatchlistLimit: null,
    CaptureLimit: null,
    AlertLimit: null
  }

  get ibRotationAva () {
    return 100 - this.ibRotation.FreebieLimit - this.ibRotation.CurrencyLimit - this.ibRotation.WatchlistLimit - this.ibRotation.AlertLimit - this.ibRotation.CaptureLimit
  }

  futuRotation: Record<string, number> = {
    FreebieLimit: null,
    CurrencyLimit: null,
    WatchlistLimit: null,
    CaptureLimit: null,
    AlertLimit: null
  }

  get futuRotationAva () {
    return 300 - this.futuRotation.FreebieLimit - this.futuRotation.CurrencyLimit - this.futuRotation.WatchlistLimit - this.futuRotation.AlertLimit - this.futuRotation.CaptureLimit
  }

  userSettings: Record<string, any> = {
    StockDataSource: '',
    PortfolioDataSource: ''
  }

  notBot = {
    Token: '',
    NameOfBot: ''
  }

  sysSettings: Record<string, any> = {
    DataPointsPerMinute: 'All',
    AlertDataSource: '',
    FreebieDataSource: '',
    FlexRefreshStart: '',
    FlexRefreshEnd: '',
    FlexTryInterval: 30
  }

  async loadUserSettings () {
    const keys = Object.keys(this.userSettings)
    const usRes = await settingService.readUserSettingsByNamesAsync(keys)
    if (usRes.Error) {
      await this.$alert(usRes.Error)
    }
    if (usRes.Result) {
      usRes.Result.forEach(p => {
        if (p.Value === 'true') this.userSettings[p.Name] = true
        else if (p.Value === 'false') this.userSettings[p.Name] = false
        else {
          this.userSettings[p.Name] = p.Value
        }
      })
    }
  }

  async updateUserSetting (field: string) {
    const value = this.userSettings[field].toString()
    const res = await settingService.addOrUpdateUserSettingAsync([{
      Name: field,
      Value: value,
      ValueType: 'Number'
    }])
    if (res.Result === true && field === 'StockDataSource') {
      localStorage.setItem('StockDataSource', value)
      if (value === 'Server') {
        socket.Local.stopAutoConnect()
        socket.Local.close()
      }
    }
    this.showMessage(res)
  }

  async deleteRss (item: RssFeeds) {
    utility.removeArrayItem(this.rssList, item)
    await this.saveSysSettings('Rss', this.rssList)
  }

  async saveSysSettings (name: string, value: any, valueType = 'json') {
    const res = await settingService.addOrUpdateSysSettingAsync([
      {
        Name: name,
        Value: valueType === 'json' ? JSON.stringify(value) : value.toString(),
        ValueType: 'Json'
      }
    ])
    this.showMessage(res)
  }

  submitGateway () {
    this.$refs.gateWayForm.validate(async (valid: boolean) => {
      await this.saveSysSettings('Gateway', this.gateway)
    })
  }

  submitNotBot () {
    this.$refs.notBotForm.validate(async (valid: boolean) => {
      if (valid) {
        await this.saveSysSettings('NotBot', this.notBot)
      }
    })
  }

  async saveNewFeedLink (model: RssFeeds) {
    this.rssList.push(model)
    await this.saveSysSettings('Rss', this.rssList)
  }

  async updateSysSetting (key: string) {
    await this.saveSysSettings(key, this.sysSettings[key], 'string')
  }

  async updateIbRotationSettings () {
    if (this.ibRotationAva < 0) {
      await this.$alert('Total pipe limit should <= 100')
      await this.loadRotation()
      return
    }
    await this.saveSysSettings('Rotation', this.ibRotation)
  }

  async updateFutuRotationSettings () {
    if (this.futuRotationAva < 0) {
      await this.$alert('Total pipe limit should <= 300')
      await this.loadRotation()
      return
    }
    await this.saveSysSettings('FutuRotation', this.futuRotation)
  }

  async mounted () {
    const res = await settingService.readSysSettingByNamesAsync(['NotBot', 'Rss', 'Gateway', 'DataPointsPerMinute', 'AlertDataSource', 'FreebieDataSource', 'FlexRefreshStart', 'FlexRefreshEnd', 'FlexTryInterval'])
    const notBotItem = res.Result.find(p => p.Name === 'NotBot')
    if (notBotItem) {
      const notBotObj = JSON.parse(notBotItem.Value)
      this.notBot.Token = notBotObj.Token
      this.notBot.NameOfBot = notBotObj.NameOfBot
    }
    const rss = res.Result.find(p => p.Name === 'Rss')
    if (rss) {
      this.rssList = JSON.parse(rss.Value) as RssFeeds[]
    }
    const gateway = res.Result.find(p => p.Name === 'Gateway')
    if (gateway) {
      const gatewayObj = JSON.parse(gateway.Value)
      this.gateway.Host = gatewayObj.Host
      this.gateway.Port = gatewayObj.Port
    }
    const dppm = res.Result.find(p => p.Name === 'DataPointsPerMinute')
    if (dppm) {
      this.sysSettings.DataPointsPerMinute = parseInt(dppm.Value)
    }
    const ads = res.Result.find(p => p.Name === 'AlertDataSource')
    if (ads) {
      this.sysSettings.AlertDataSource = ads.Value
    }
    const fds = res.Result.find(p => p.Name === 'FreebieDataSource')
    if (fds) {
      this.sysSettings.FreebieDataSource = fds.Value
    }
    const frs = res.Result.find(p => p.Name === 'FlexRefreshStart')
    if (frs) {
      this.sysSettings.FlexRefreshStart = frs.Value
    }
    const fre = res.Result.find(p => p.Name === 'FlexRefreshEnd')
    if (fre) {
      this.sysSettings.FlexRefreshEnd = fre.Value
    }
    const fti = res.Result.find(p => p.Name === 'FlexTryInterval')
    if (fti) {
      this.sysSettings.FlexTryInterval = fti.Value
    }
    await this.loadRotation()
    await this.loadUserSettings()
  }

  async loadRotation () {
    const res = await settingService.readSysSettingByNamesAsync(['Rotation', 'FutuRotation'])
    const ibRotation = res.Result.find(p => p.Name === 'Rotation')
    if (ibRotation) {
      const rotationObj = JSON.parse(ibRotation.Value)
      this.ibRotation.CurrencyLimit = rotationObj.CurrencyLimit
      this.ibRotation.FreebieLimit = rotationObj.FreebieLimit
      this.ibRotation.WatchlistLimit = rotationObj.WatchlistLimit
      this.ibRotation.CaptureLimit = rotationObj.CaptureLimit
      this.ibRotation.AlertLimit = rotationObj.AlertLimit
    }
    const futuRotation = res.Result.find(p => p.Name === 'FutuRotation')
    if (futuRotation) {
      const rotationObj = JSON.parse(futuRotation.Value)
      this.futuRotation.FreebieLimit = rotationObj.FreebieLimit
      this.futuRotation.WatchlistLimit = rotationObj.WatchlistLimit
      this.futuRotation.AlertLimit = rotationObj.AlertLimit
      this.futuRotation.CaptureLimit = rotationObj.CaptureLimit
    }
  }

  private showMessage (res: ApiBooleanResult) {
    if (res.Error) {
      this.$message({ message: this.$t('msg.' + (res.Error || 'unableToSave')) as string, type: 'error' })
    } else {
      this.$message({ message: this.$t('msg.dataSaved') as string, type: 'success' })
    }
  }
}
